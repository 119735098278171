import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`In 1995, a group of parents seeking a genuinely international school experience for their children in Kyiv worked together to found Pechersk School International, Kyiv (PSI) as a private, non-profit school. In 2003, the school moved to its present site.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/edcf041aefe02d01f231ca5e2a7154a1/5ef17/01.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEBf/EABYBAQEBAAAAAAAAAAAAAAAAAAABA//aAAwDAQACEAMQAAABS++YzTSJn//EABkQAAIDAQAAAAAAAAAAAAAAAAECAAMRMf/aAAgBAQABBQKuvIyAlgyMygHZ2f/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwFsf//EABYRAQEBAAAAAAAAAAAAAAAAAAARE//aAAgBAgEBPwGMX//EABsQAAIBBQAAAAAAAAAAAAAAAAABMRARITJh/9oACAEBAAY/AtBY4NWERFP/xAAcEAEAAgIDAQAAAAAAAAAAAAABACERMUFhcdH/2gAIAQEAAT8h+oTBAexGjo8EMY3sOVTZ9n//2gAMAwEAAgADAAAAEPDf/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAExUf/aAAgBAwEBPxByGx//xAAWEQEBAQAAAAAAAAAAAAAAAAABAEH/2gAIAQIBAT8QBslb/8QAHBABAAIDAAMAAAAAAAAAAAAAAREhADFBUWFx/9oACAEBAAE/EErOQhut9TqO5ChwS0gAd+YhcqLBORkQFlN/cIUNzw358x7wBaTbd5//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "01",
            "title": "01",
            "src": "/static/edcf041aefe02d01f231ca5e2a7154a1/5ef17/01.jpg",
            "srcSet": ["/static/edcf041aefe02d01f231ca5e2a7154a1/f93b5/01.jpg 300w", "/static/edcf041aefe02d01f231ca5e2a7154a1/b4294/01.jpg 600w", "/static/edcf041aefe02d01f231ca5e2a7154a1/5ef17/01.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As an English-medium school serving international and host national families in Kyiv, PSI attracts students from the diplomatic, government and business communities.`}</p>
    <p>{`Since 2000, PSI has been authorised to offer the Primary Years (PYP), Middle Years (MYP) and Diploma Programmes (DP) of the International Baccalaureate® (IB) programmes. The programmes encourage both personal and academic achievement. Research from leading institutions demonstrates that IB students are more likely than their peers to succeed in higher education.`}</p>
    <p>{`Our extensive extracurricular activities programme provides students with access to a wide range of clubs and sports, including overseas competitions, drama, music, and community service activities.`}</p>
    <p>{`The school is proud of its vibrant and diverse international community. We educate more than 500 students between the ages of 3 and 18 from close to 50 different countries. Students and staff joining PSI quickly feel included and valued, with a sense of being safe and cared for in a challenging and motivating learning environment. This reality reflects our school motto: ‘A Place Where We Belong.’ Our goal is to create a joyful, stimulating environment where everyone feels motivated and challenged, and has the opportunity to be creative and to take the risks necessary for deep learning to be accomplished.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      